import { LoadingState, useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { RegisterApiClient } from "../api-client/api-client";
import { RegisterState } from "./store";

const pageSize = 1000 * 60 * 60 * 24 * 100;

export const useRegister = () => {
  const [register, setRegister] = useRecoilState(RegisterState);
  const [loading, setLoading] = useRecoilState(LoadingState("useRegister"));

  const refresh = useCallback(
    async (from: number, to?: number) => {
      setLoading(true);
      setRegister({
        ...(await RegisterApiClient.getRegister(from, to)),
        from,
        to: to || Date.now(),
      });
      setLoading(false);
    },
    [setRegister, setLoading]
  );

  const loadMore = useCallback(async () => {
    setLoading(true);
    if (register) {
      const from = (register?.from || Date.now()) - pageSize;
      const to = register?.from;
      const more = await RegisterApiClient.getRegister(from, to);
      setRegister({
        ...register,
        Movements: {
          Detail: _.uniqBy(
            [
              ...(register?.Movements?.Detail || []),
              ...(more?.Movements?.Detail || []),
            ],
            (m) => m.Date
          ),
        },
        StartDateBalanceDec: more?.StartDateBalanceDec,
        StartDateBalanceInt: more?.StartDateBalanceInt,
        from,
      });
    } else {
      await refresh(Date.now() - pageSize);
    }
    setLoading(false);
  }, [register, setRegister, setLoading, refresh]);

  useGlobalEffect(
    "useRegister",
    () => {
      loadMore();
    },
    []
  );

  return {
    loading,
    refresh,
    loadMore,
    register,
  };
};
