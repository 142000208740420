import { Button } from "@atoms/button/button";
import InputCode from "@atoms/input/input-code";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import Link from "@atoms/link";
import { Info, Section, Subtitle } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { AuthApiClient } from "@features/auth/api-client/api-client";
import { useAuth } from "@features/auth/state/use-auth";
import { validateEmail } from "@features/utils/strings";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { loading: authLoading, loginWithMagicLink } = useAuth();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-center">
        <Section>{t("sign_in.first_page.title")}</Section>
        <Subtitle>{t("sign_in.first_page.subtitle")}</Subtitle>
      </div>

      {authLoading && <PageLoader />}
      {!authLoading && step === 0 && (
        <>
          <InputLabel
            label={t("sign_in.first_page.email")}
            className="mt-4"
            input={
              <Input
                value={email}
                placeholder="email@server.co"
                onChange={(e) => setEmail(e.target.value)}
              />
            }
          />

          <Info className="block mt-4">{t("sign_in.first_page.info")}</Info>

          <div className="text-center mt-6">
            <Button
              size="lg"
              loading={loading}
              disabled={!validateEmail(email)}
              onClick={async () => {
                setLoading(true);
                try {
                  const resp = await AuthApiClient.loginWithMagicLink(email);
                  if (resp.token) {
                    setToken(resp.token);
                    setStep(1);
                  } else {
                    toast.error(t("sign_in.toast_error"));
                  }
                } catch (e) {
                  toast.error("An error occurred: " + e);
                }
                setLoading(false);
              }}
            >
              {t("sign_in.first_page.confirm")}
            </Button>
          </div>
        </>
      )}
      {!authLoading && step === 1 && (
        <>
          <InputLabel
            label={t("sign_in.code_page.code", { email })}
            className="mt-8 text-center"
            input={<InputCode value={code} onComplete={(e) => setCode(e)} />}
          />

          <Info className="block mt-4">{t("sign_in.code_page.info")}</Info>

          <div className="text-center mt-6">
            <Button
              size="lg"
              loading={loading}
              disabled={code.length !== 6}
              onClick={async () => {
                setLoading(true);
                try {
                  const resp = await loginWithMagicLink(email, token, code);
                  if (resp) {
                    toast.success(t("sign_in.toast_success"));
                  } else {
                    toast.error(t("sign_in.toast_code_error"));
                  }
                } catch (e) {
                  toast.error("An error occurred: " + e);
                }
                setLoading(false);
              }}
            >
              {t("sign_in.code_page.confirm")}
            </Button>

            <br />
            <br />
            <Link
              onClick={() => {
                setStep(0);
                setCode("");
              }}
            >
              {t("sign_in.code_page.try_again")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
