export const AuthJWT: {
  token: string | null;
  refresh: string | null;
  empty: () => void;
} = {
  token: null,
  refresh: null,
  empty: () => {
    AuthJWT.token = null;
    AuthJWT.refresh = null;
  },
};
