import environment from "@config/environment";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { atomFamily } from "recoil";
import { AuthJWT } from "./auth/jwt";
import i18n from "./i18n";

const globalEffectDepsMap = new Map<string, ReadonlyArray<any>>();

export const flushGlobalEffects = () => {
  globalEffectDepsMap.clear();
};

export const useGlobalEffect = (
  key: string,
  callback: (...args: any[]) => any,
  deps: ReadonlyArray<any>
) => {
  //Hack to avoid linter telling us what to do
  (useEffect as any)(() => {
    if (isEqual(globalEffectDepsMap.get(key), deps) === false) {
      globalEffectDepsMap.set(key, deps);
      return callback();
    }
  }, deps as any[]);
};

export const useEffectUnstable = useEffect as (
  callback: () => void | (() => void),
  dependencies: any[]
) => void;

const toFormData = (object: any) => {
  const formData = new FormData();
  for (const property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) continue;
    formData.append(property, object[property]);
  }
  return formData;
};

export const useFetch = async (
  url: string,
  options: { formData?: boolean } & RequestInit
): Promise<Response> => {
  if (options?.formData) {
    options.body = toFormData(JSON.parse((options.body || "{}") as string));
  }
  if (!url.startsWith("http"))
    url = `${environment.server.replace(/\/$/, "")}/${url.replace(/^\//, "")}`;
  options.headers = {
    ...(options?.formData ? {} : { "Content-Type": "application/json" }),
    ...(AuthJWT.token ? { Authorization: `Bearer ${AuthJWT.token}` } : {}),
    "Accept-Language": i18n.language,
    ...options.headers,
  };
  const data = await fetch(url, options);
  if (data.status === 401) {
    document.location.reload();
  }
  return data;
};

export const LoadingState = atomFamily({
  key: "LoadingState",
  //It is possible to set here what loaders should init as loading
  default: (type: string) => ["useAuth"].includes(type) || false,
});

const delayedRequests: Map<string, () => void | Promise<void>> = new Map();
const delayedRequestsHasTimout: Map<string, boolean> = new Map();

export const delayRequest = (
  key: string,
  request: () => void | Promise<void>,
  options: { timeout: number; doInitialCall: boolean } = {
    timeout: 1000,
    doInitialCall: true,
  }
) => {
  if (!delayedRequestsHasTimout.has(key)) {
    delayedRequestsHasTimout.set(key, true);
    if (options.doInitialCall) request();
    else delayedRequests.set(key, request);
    setTimeout(() => {
      const request = delayedRequests.get(key);
      delayedRequestsHasTimout.delete(key);
      request &&
        delayRequest(key, request, { ...options, doInitialCall: true });
      delayedRequests.delete(key);
    }, options.timeout);
  } else {
    delayedRequests.set(key, request);
  }
};
