import { atom } from "recoil";

export type UserType = {
  id: string;
  email: string;
  name: string;
};

export type AuthType = {
  isLoggedIn: boolean;
  authorization: string;
  user: UserType | null;
};

export const AuthState = atom<AuthType>({
  key: "AuthState",
  default: (() => {
    try {
      return {
        isLoggedIn: false,
        authorization: JSON.parse(
          localStorage.getItem("user.access_token") || "null"
        ),
        user: null,
      };
    } catch (e) {
      return {
        isLoggedIn: false,
        authorization: null,
        user: null,
      };
    }
  })(),
});
