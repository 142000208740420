import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info } from "@atoms/text";
import { useAuth } from "@features/auth/state/use-auth";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { user, logout } = useAuth();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={openAccountModal}
        onClose={() => {
          setOpenAccountModal(false);
        }}
      >
        <ModalContent title={t("account.menu_title")}>
          <Info className="mb-4 block">
            {t("account.menu_text", { email: user?.email })}
          </Info>
          <Button
            theme="danger"
            onClick={() => {
              logout();
            }}
          >
            {t("account.logout")}
          </Button>
        </ModalContent>
      </Modal>
      <div
        className="bg-blue-700 h-20 rounded-b-lg flex flex-row justify-center items-center px-4 min-h-0 shrink-0 bg-contain bg-no-repeat bg-left-bottom shadow-lg"
        style={{ backgroundImage: "url(/images/header-background.png)" }}
      >
        <img
          className="mx-auto h-20 w-auto"
          src="/images/kycoin-logo-color-white.png"
          alt="Nun-Tech"
        />
        <div className="grow"></div>
        <div
          onClick={() => {
            setOpenAccountModal(true);
          }}
          className="text-white flex items-center hover:opacity-75 cursor-pointer"
        >
          <div className="mr-2">
            <Base noColor>{user?.name}</Base>
            <Info className="block">{user?.email}</Info>
          </div>
          <ChevronDownIcon className="h-4 w-4" />
        </div>
      </div>
    </>
  );
};
