import { Button } from "@atoms/button/button";
import { Loader } from "@atoms/loader";
import { Base, Info, Section, Title } from "@atoms/text";
import { useRegister } from "@features/register/state/use-register";
import { MovementType } from "@features/register/types";
import { formatTime } from "@features/utils/dates";
import { useTranslation } from "react-i18next";
import { Page } from "../_layout/page";

export const Dashboard = () => {
  const { register, loading, loadMore } = useRegister();
  const { t } = useTranslation();

  return (
    <Page>
      <Title noColor>{t("dashboard.balance")}</Title>
      {!register?.Token && (
        <div className="flex items-center justify-center h-40 w-full">
          <Loader />
        </div>
      )}
      {!!register?.Token && (
        <>
          <div className="bg-black bg-opacity-25 p-4 rounded-lg mt-4">
            <Section noColor>
              <div
                className={
                  "flex items-center " +
                  (parseInt(register?.EndDateBalanceInt?._text || "0") <= 0
                    ? "text-red-400 "
                    : "")
                }
              >
                <img
                  alt="KYC"
                  src="/images/kyc-crypto-icon.png"
                  className="h-5 w-5 inline-block mr-2"
                />
                <span>
                  {parseFloat(
                    register?.EndDateBalanceInt?._text || "0"
                  ).toLocaleString(navigator.language, {
                    useGrouping: true,
                    minimumFractionDigits: 0,
                  })}{" "}
                  <Info noColor className="opacity-75">
                    KYC
                  </Info>
                </span>
              </div>
            </Section>
            <Base noColor className="opacity-50">
              {t("dashboard.updated", { to: formatTime(register.to) })}
            </Base>
          </div>

          <br />
          <br />
          <Title className="text-white" noColor>
            {t("dashboard.last_operations")}
          </Title>

          <div className="bg-black bg-opacity-25 p-2 rounded-lg mt-4">
            {!(register?.Movements?.Detail || [])?.length && (
              <div className="p-4 text-center w-full">
                {t("dashboard.no_operations")}
              </div>
            )}
            {(register?.Movements?.Detail || [])?.map((movement) => {
              return <Movement movement={movement} />;
            })}
          </div>

          <div className="w-full text-center mt-4">
            <Info>
              {t("dashboard.last_operations_info", {
                from: formatTime(register.from),
              })}
            </Info>
            <br />
            <Button
              loading={loading}
              theme="default"
              size="sm"
              className="mt-2"
              onClick={() => {
                loadMore();
              }}
            >
              {t("dashboard.see_more")}
            </Button>
          </div>
        </>
      )}
    </Page>
  );
};

export const Movement = ({ movement }: { movement: MovementType }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row hover:bg-black hover:bg-opacity-10 p-4 rounded-md">
      <div className="shrink-0 sm:w-40">
        <div> {t("dashboard.operation_types." + movement.Type?._text)}</div>
        <Info>{formatTime(movement.Date?._text || "")}</Info>
      </div>
      <div className="grow sm:hidden block" />
      <div className="grow ml-4 hidden sm:block">
        <div>{movement.Counterparty?._text}</div>
        <Info>{movement.RefPartner?._text}</Info>
      </div>
      <div className="ml-4">
        <div
          className={
            "w-full justify-end flex items-center " +
            (parseInt(movement?.NumberInt?._text || "0") <= 0
              ? "text-red-400 "
              : "")
          }
        >
          <img
            alt="KYC"
            src="/images/kyc-crypto-icon.png"
            className="h-5 w-5 inline-block mr-2"
          />
          <span>
            {parseFloat(movement?.NumberInt?._text || "").toLocaleString(
              navigator.language,
              {
                useGrouping: true,
                minimumFractionDigits: 0,
              }
            )}{" "}
            <Info noColor className="opacity-75">
              KYC
            </Info>
          </span>
        </div>
        <Info className="sm:hidden block w-full text-right">
          {movement.Counterparty?._text} • {movement.RefPartner?._text}
        </Info>
      </div>
    </div>
  );
};
