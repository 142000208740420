import { useFetch } from "@features/utils";
import { RegisterType } from "../types";

export class RegisterApiClient {
  static getRegister = async (from: number, to?: number) => {
    const response = await useFetch(
      `/api/v1/register?from=${from}&to=${to || Date.now()}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data as RegisterType;
  };
}
