import _ from "lodash";
import React from "react";

interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    "size"
  > {
  theme?: "plain";
  label?: string;
  size?: "sm" | "md" | "lg";
  feedback?: string;
  hasError?: boolean;
  multiline?: boolean;
  inputComponent?: React.ReactNode;
  inputClassName?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
}

export const defaultInputClassName = (theme: "plain" = "plain") => {
  return "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white rounded-xs";
};

export const errorInputClassName = (theme: "plain" = "plain") => {
  return (
    defaultInputClassName(theme) +
    " bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800"
  );
};

export const Input = (props: InputProps) => {
  let inputClassName = props.hasError
    ? errorInputClassName(props.theme)
    : defaultInputClassName(props.theme);
  inputClassName = inputClassName + (props.disabled ? " opacity-75" : "");

  if (!props.multiline) {
    if (props.size === "lg") inputClassName = inputClassName + " h-11";
    else if (props.size === "sm") inputClassName = inputClassName + " h-7";
    else inputClassName = inputClassName + " h-9";
  }

  return (
    <>
      {props.inputComponent ||
        (props.multiline ? (
          <textarea
            ref={props.inputRef as React.Ref<HTMLTextAreaElement>}
            className={
              inputClassName +
              " " +
              props.inputClassName +
              " " +
              props.className
            }
            {..._.omit(
              props as any,
              "label",
              "inputClassName",
              "className",
              "value",
              "size"
            )}
          >
            {props.value}
          </textarea>
        ) : (
          <input
            ref={props.inputRef as React.Ref<HTMLInputElement>}
            type="text"
            className={
              inputClassName +
              " " +
              props.inputClassName +
              " " +
              props.className
            }
            {..._.omit(props, "label", "inputClassName", "className", "size")}
          />
        ))}
    </>
  );
};
