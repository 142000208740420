import { PageLoader } from "@components/page-loader";
import { useAuth } from "@features/auth/state/use-auth";
import { ROUTES } from "@features/routes";
import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ClientRoutes } from "./client";
import { LoginRoutes } from "./signin";

export default function InitialRouter() {
  const { pathname } = useLocation();
  const { loading } = useAuth();

  useEffect(() => {
    console.log("Navigate to:", pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) {
    return (
      <div className="default-bg-gradient flex justify-center items-center h-full w-full">
        <PageLoader />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="default-bg-gradient flex justify-center items-center h-full w-full">
          <PageLoader />
        </div>
      }
    >
      <div className="default-bg-gradient flex min-h-full h-full">
        <Routes>
          <Route path="/">
            {ClientRoutes()}
            {LoginRoutes()}
            <Route path="*" element={<Navigate to={ROUTES.Login} />} />
            <Route path="" element={<Navigate to={ROUTES.Login} />} />
          </Route>
        </Routes>
        <Toaster position="top-center" />
      </div>
    </Suspense>
  );
}
