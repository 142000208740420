import _ from "lodash";
import { Link as L } from "react-router-dom";

export default function Link(
  props: any & {
    href?: string;
    to?: string;
    onClick?: () => void;
    children: React.ReactNode;
    noColor?: boolean;
  }
) {
  const colors = props.noColor
    ? ""
    : "hover:text-blue-700 active:text-blue-800 text-blue-500";

  if ((props.href || "")?.startsWith("http")) {
    return (
      <a
        href={props.href}
        className={colors + " " + (props.className || "")}
        {..._.omit(props, "children", "className", "noColor")}
      >
        {props.children}
      </a>
    );
  }

  return (
    <L
      to={(!props.onClick && props.href) || "#"}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      className={colors + " " + (props.className || "")}
      {..._.omit(props, "children", "className", "noColor")}
    >
      {props.children}
    </L>
  );
}
