import { AuthState, AuthType } from "@features/auth/state/store";
import { ROUTES } from "@features/routes";
import {
  flushGlobalEffects,
  LoadingState,
  useEffectUnstable,
  useGlobalEffect,
} from "@features/utils";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilState } from "recoil";
import { AuthApiClient } from "../api-client/api-client";
import { AuthJWT } from "../jwt";

export let logoutFromService = () => {};

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(AuthState);
  const [loading, setLoading] = useRecoilState(LoadingState("useAuth"));
  const navigate = useNavigate();

  useGlobalEffect(
    "useAuth",
    () => {
      setLoading(true);
    },
    []
  );

  const getExtractedToken = () => {
    if (!AuthJWT.token) return null;
    return jwt_decode(AuthJWT.token) as {
      id: string;
      lang: string;
      role: string;
      mfa: {
        method: string;
        exp: number;
      }[];
      ip: string;
      created_at: number;
      req_id: string;
      sandbox: boolean;
      iat: number;
      exp: number;
    };
  };

  const refreshUser = async () => {
    const xmlUser = await AuthApiClient.getUser();
    if (!xmlUser || !xmlUser.Email) {
      logout();
      return false;
    }

    const user = {
      id: xmlUser.NaturalPerson?._text || "",
      email: xmlUser.Email?._text || "",
      name: xmlUser.FullName?._text || "",
    };
    setAuth({
      ...auth,
      user,
      isLoggedIn: true,
      authorization: AuthJWT.token || "",
    });

    return true;
  };

  const renewToken = async () => {
    const { access_token, expires_at } = await AuthApiClient.renewToken();

    if (!access_token) {
      logout();
      return false;
    }
    AuthJWT.token = access_token;

    if (expires_at && access_token) {
      setTimeout(() => {
        renewToken();
      }, expires_at - Date.now());
    }

    await refreshUser();
  };

  const loginWithMagicLink = async (
    email: string,
    token: string,
    code: string
  ) => {
    const { access_token, expires_at } = await AuthApiClient.loginWithMagicLink(
      email,
      token,
      code
    );

    if (!access_token) {
      logout();
      return false;
    }
    AuthJWT.token = access_token;

    if (expires_at) {
      setTimeout(() => {}, expires_at - Date.now());
    }

    return await refreshUser();
  };

  const logout = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        AuthJWT.empty();
        const updated = {
          ...snapshot.getLoadable(AuthState).contents,
          isLoggedIn: false,
          authorization: null,
          authorizationRefresh: null,
          user: null,
        };
        setAuth(updated);
        saveAuth(updated);
        flushGlobalEffects();
        navigate(ROUTES.Login);
      },
    []
  );

  logoutFromService = logout;

  const saveAuth = useCallback(
    (updated?: AuthType) => {
      localStorage.setItem(
        "user.access_token",
        JSON.stringify(updated ? updated?.authorization : auth?.authorization)
      );
    },
    [auth?.authorization]
  );

  useEffectUnstable(() => {
    if (!auth.isLoggedIn) {
      if (auth.authorization) {
        AuthJWT.token = auth.authorization;
        renewToken();
      } else {
        logout();
        setLoading(false);
      }
      return;
    }

    saveAuth();
    setLoading(false);
  }, [auth.isLoggedIn, auth.authorization, setLoading, saveAuth, logout]);

  //Set defaults for user
  const user = _.cloneDeep(auth.user);
  if (user) {
    //TODO set defaults for user
  }

  return {
    loading,
    user,
    language: "en",
    logout,
    loginWithMagicLink,
    refreshUser,
    getExtractedToken,
  };
};
