import { useFetch } from "@features/utils";
import { PersonType } from "../types";

export class AuthApiClient {
  static renewToken = async () => {
    const response = await useFetch(`/api/v1/auth/renew`, {
      method: "GET",
    });
    const data = await response.json();
    return data as {
      access_token: string;
      expires_at: number;
    };
  };

  static loginWithMagicLink = async (
    email: string,
    token?: string,
    code?: string
  ) => {
    const response = await useFetch(`/api/v1/auth/token`, {
      method: "POST",
      body: JSON.stringify({
        email,
        token,
        code,
      }),
    });
    const data = await response.json();
    return data as {
      access_token?: string;
      expires_at?: number;
      token?: string;
    };
  };

  static getUser = async () => {
    const response = await useFetch(`/api/v1/auth/me`, {
      method: "GET",
    });
    const data = await response.json();
    return data as PersonType;
  };
}
