import { ROUTES } from "@features/routes";
import { Modals } from "@views/modals";
import { Outlet, Route } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { Header } from "./_layout/header";

export const ClientRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path={ROUTES.Dashboard} element={<Dashboard />} />
    </Route>
  );
};

export const Layout = () => {
  return (
    <div className="flex w-full grow flex-col h-full">
      <Header />
      <div className="grow flex min-h-0">
        <div className="grow min-h-0 overflow-auto">
          <Outlet />
        </div>
      </div>
      <Modals />
    </div>
  );
};
